@import '../../styles/variables';

.search-note__item:first-child {
  border-radius: 6px 6px 0 0;
}

.search-note__item:last-child {
  border-radius: 0 0 6px 6px;
}

.search-note__item {
  padding: 0.6rem 0.4rem;
  cursor: pointer;
  background-color: $app-bg-color;
  border-bottom: $app-palette-medium 1px solid;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  &:hover,
  &:active {
    color: $app-palette-primary;
  }
}
