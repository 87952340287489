// Font
$sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;

$monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;

// Colors
$material-primary: #bb86fc;
$material-p-variant: #3700b3;
$material-secondary: #03dac6;
$material-background: #121212;
$material-error: #cf6679;

// Make the secondary are the same colors @ 50% transparency.

// Black-Navy-Orange palette
// $app-palette-darkest: #082032;
// $app-palette-dark: #2C394B;
// $app-palette-medium: #334756;
// $app-palette-primary: #FF4C29;
// $app-palette-light: #FFFFFF;

// Black-Grey-Red palette
// $app-palette-darkest: #171717;
// $app-palette-dark: #444444;
// $app-palette-medium: #444444;
// $app-palette-primary: #DA0037;
// $app-palette-light: #EDEDED;

// Black-Grey-Orange palette
$app-palette-darkest: #222831;
$app-palette-dark: #393e46;
$app-palette-medium: #393e46;
$app-palette-primary: #d65a31;
$app-palette-light: #eeeeee;

$palette-pink: #e91e63;
$palette-pink-dark: #c21858;
$palette-purple: #9c2780;
$palette-purple-dark: #572780;
$palette-blue-dark: #272a80;
$palette-blue: #276880;
$palette-blue-light: #57acdc;
$palette-green-light: #570cbe;
$palette-green: #60c689;

$app-bg-color: $app-palette-darkest;
// this is the one that comes with the package
$app-codemirror-default-bg: #282c34;

$input-focus-border: 3px;
