@import './_variables.scss';

.side-panel__container {
  height: 100vh;
  width: auto;
  max-width: 200px;
  color: inherit;
  border-right: $app-palette-medium 1px solid;
  display: flex;
  flex-direction: column;
}

.side-panel__header {
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;
}

.side-panel__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.flex-1 {
  flex: 1;
}

.scroll-y {
  overflow-y: auto;
}

.side-panel__header.iconify {
  justify-content: flex-start;
}

.side-panel__action {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.side-panel__action:hover {
  color: $app-palette-primary;
}

.note-page {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.empty-page {
  background-color: $app-codemirror-default-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.empty-page__content {
  text-align: center;
  padding: 1rem;
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
    color: #ccc;
  }
  button {
    background-color: $app-palette-primary;
    padding: 0.6rem;
    outline: none;
    border-radius: 10px;
    border: $app-palette-primary 3px solid;
    color: white;
    display: flex;
    align-items: center;
    margin: 2rem auto;
    cursor: pointer;
    svg {
      margin: 0 8px 0 0;
    }
    &:hover,
    &:active {
      background-color: $app-palette-darkest;
      border: $app-palette-primary 3px solid;
      // color: $app-palette-primary;
    }
  }
}
