@import './_variables.scss';
.note-item {
  border-bottom: $app-palette-medium 1px solid;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.note-item:hover,
.note-item:active,
.menu-item:hover,
.menu-item:active {
  background-color: $app-palette-dark;
}

.menu-item {
  display: flex;
  cursor: pointer;
  padding: 0.75rem;
  align-items: center;
}

.menu-item:hover,
.menu-item:active,
.menu-item.active {
  color: $app-palette-primary;
}

.note-item__info {
  overflow: hidden;
}

.menu-item > * {
  font-size: 0.95rem;
  margin: 0 0.6rem 0 0;
}

.note-item__info h4 {
  margin: 0 0 0.5rem 0;
  font-size: 0.95rem;
  /* line-height: 1.75rem; */
}

.note-item__info h5 {
  color: darken($app-palette-light, 50%);
  margin: 0;
  font-size: 0.75rem;
  /* line-height: 1.75rem; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.note-item__action {
  display: none;
  width: 20px;
}

.note-section {
  border-top: $app-palette-medium 1px solid;
}
