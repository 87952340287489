@import './_variables.scss';

.editor__container {
  /* width: 100vw; */
  height: 100vh;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
}

.editor__split-panel {
  display: flex;
  width: 100%;
  overflow-y: hidden;
  flex: 1;
  background-color: $app-codemirror-default-bg;
  border: rgba(0, 0, 0, 0.3) 1px solid;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.6);
}

.editor__split-panel.splitted .editor__edit {
  border-right: $app-palette-medium 1px solid;
}

.editor__preview,
.editor__edit {
  // border: black 1px solid;
  /* border-radius: 10px; */
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
}

.editor__edit {
  padding: 1rem 0;
  font-size: 1rem;
}

.editor-actions__container {
  height: 32px;
  width: 100%;
  color: inherit;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.editor-actions {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // padding: 0 1rem;
  width: 100%;
  height: 100%;
  background-color: $app-palette-darkest;
  // border: black 1px solid;
}

.editor-actions > section {
  display: flex;
  height: 100%;
  align-items: center;
}

.editor-actions > section > * {
  margin-right: 0.75rem;
  height: 100%;
  cursor: pointer;
  width: 34px;
  padding: 0 6px;
}

.editor-actions > section > span {
  width: auto;
  height: auto;
  font-size: 0.7rem;
  font-style: italic;
  color: #6a6a6a;
}

.editor-actions > section > *:last-child {
  margin-right: 0;
}

.editor-actions > section > *:hover,
.editor-actions > section > *:active {
  color: $app-palette-primary;
  background-color: $app-palette-darkest;
}

.editor-actions > section > *.active {
  color: $app-palette-primary;
}
