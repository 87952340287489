@import '../../styles/variables';

.search-note__container {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .search-note__content {
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    input {
      padding: 0.5rem;
      background-color: $app-bg-color;
      color: $app-palette-light;
      border: transparent $input-focus-border solid;
      border-radius: 6px;
      border-bottom-color: $app-palette-medium;
      &:focus,
      &:focus-visible,
      &:focus,
      &:focus-visible {
        // outline: $app-palette-primary auto 1px;
        outline: none;
        border: $app-palette-primary $input-focus-border solid;
      }
    }
    .search-note__results {
      margin-top: 0.25rem;
      overflow-y: auto;
      background-color: $app-bg-color;
      border-radius: 6px;
    }
  }
}
